import React, { useState, useEffect, useContext, useRef } from "react"
import { navigate } from "gatsby"
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"
import { useEffectOnce } from "react-use"
import queryString from "query-string"
import { useForm } from "react-hook-form"
import { motion, AnimatePresence } from "framer-motion"

/* Import Global Context(s) */
import FirebaseContext from "~context/firebase"

/* Import Global Hook(s) */
import useFirebaseUser from "~hooks/useFirebaseUser"

/* Import Global Component(s) */
import Footer from "~components/footer/footer"
import Page from "~components/page/page"
import PageHeader from "~components/page/components/pageHeader/pageHeader"
import PageArticle from "~components/page/components/pageArticle/pageArticle"

/* Import Global Animation(s) */
import elementTransitionAnimation from "~animations/elementTransition"

/* Import Local Style(s) */
import "./firebase.scss"

const FirebaseEmailActionHandler = ({ location }) => {
  const firebase = useContext(FirebaseContext)
  const firebaseUser = useFirebaseUser()
  const [feedback, setFeedback] = useState()
  const [mode, setMode] = useState()
  const [actionCode, setActionCode] = useState()
  const [continueUrl, setContinueUrl] = useState()
  const formRef = useRef()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  useEffectOnce(() => {
    const {
      mode,
      oobCode: actionCode,
      continueUrl,
    } = queryString.parse(location.search)
    // Handle the user management action.
    // mode: the action to complete.
    setMode(mode)
    // actionCode: the one-time code from the query parameter.
    setActionCode(actionCode)
    // continueUrl: the continue URL from the query parameter if available.
    setContinueUrl(continueUrl)
  })

  if (firebaseUser && location.pathname !== `/vip/account`) {
    if (typeof window !== `undefined`) {
      navigate("/vip/account")
      return null
    }
  }

  const onSubmit = ({ password }) => {
    if (!password) {
      return
    }
    firebase
      .auth()
      .verifyPasswordResetCode(actionCode)
      .then(email => {
        const accountEmail = email

        // TODO: Show the reset screen with the user's email and ask the user for
        // the new password.
        const newPassword = password

        // Save the new password.
        firebase
          .auth()
          .confirmPasswordReset(actionCode, newPassword)
          .then(response => {
            // Password reset has been confirmed and new password updated.
            // TODO: Display a link back to the app, or sign-in the user directly
            firebase
              .auth()
              .signInWithEmailAndPassword(email, newPassword)
              .then(userCredential => {
                // Signed in
                const user = userCredential.user
                console.log("logged in", user)
              })
              .catch(error => {
                const errorCode = error.code
                const errorMessage = error.message
                console.log("error", errorCode, errorMessage)
              })
            // if the page belongs to the same domain as the app:
            // auth.signInWithEmailAndPassword(accountEmail, newPassword);
            // TODO: If a continue URL is available, display a button which on
            // click redirects the user back to the app via continueUrl with
            // additional state determined from that URL's parameters.
          })
          .catch(error => {
            console.log(error)
            // Error occurred during confirmation. The code might have expired or the
            // password is too weak.
            const errorCode = error.code
            const errorMessage = error.message
            // set error messages
            console.log("error", errorCode, errorMessage)
            switch (errorCode) {
              case "auth/internal-error":
                setFeedback("Unable to reset password.")
                break
              default:
                break
            }
          })
      })
      .catch(error => {
        console.log(error)
        // Invalid or expired action code. Ask user to try to reset the password
        // again.
      })
  }

  // errors to handle
  //   firebase
  //     auth/internal-error

  return (
    <Page title="Firebase" location={location}>
      <PageHeader title={"Firebase"} location={location} />
      <PageArticle>
        {/* {mode && mode === "resetPassword" && ( */}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="log-in"
          ref={formRef}
        >
          <input
            type="password"
            placeholder="Password"
            {...register("password", { required: true })}
          />
          <AnimatePresence initial={false}>
            {feedback && (
              <motion.p
                className="feedback"
                initial="initial"
                animate="animate"
                exit="exit"
                variants={elementTransitionAnimation.variants}
              >
                {feedback}
              </motion.p>
            )}
          </AnimatePresence>
          <input type="submit" value="Reset Password" />
        </form>
        <div>
          <Link to="/vip/register">return</Link>
        </div>
      </PageArticle>
      <Footer />
    </Page>
  )
}

export default FirebaseEmailActionHandler
